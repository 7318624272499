<template>
  <div class="row">
    <div class="col-md-12">
      <v-card class="p-5">
        <form class="form mt-0 mt-lg-10" id="kt_form">
          <div class="pb-5" data-wizard-type="step-content">
            <div class="mb-10 font-weight-bold text-dark">User Details</div>
            <div class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <label>Name</label>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    name="companyName"
                    v-model="$v.form.first_name.$model"
                    :state="validateState('first_name')"
                    aria-describedby="input-company-name-feedback"
                    :placeholder="'Name'"
                  ></b-form-input>
                  <span
                    id="input-company-name-feedback"
                    class="form-text text-muted"
                    >Please enter User's First Name</span
                  >
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <label>Last Name</label>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    name="companyName"
                    v-model="$v.form.last_name.$model"
                    :state="validateState('last_name')"
                    aria-describedby="input-company-name-feedback"
                    :placeholder="'Last Name'"
                  ></b-form-input>
                  <span
                    id="input-company-name-feedback"
                    class="form-text text-muted"
                    >Please enter User's Last Name</span
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-12">
                <div class="form-group">
                  <label>Email</label>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    name="email"
                    :disabled="$route.params.id != 0"
                    type="text"
                    v-model="$v.form.email.$model"
                    :state="validateState('email')"
                    aria-describedby="input-company-password-feedback"
                    :placeholder="'Email'"
                  ></b-form-input>
                  <span
                    id="input-company-password-feedback"
                    class="form-text text-muted"
                  >
                    Please enter user email
                  </span>
                </div>
              </div>
            </div>
            <div v-if="$route.params.id == 0" class="row">
              <div class="col-xl-6">
                <div class="form-group">
                  <label>Password</label>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    name="password"
                    type="password"
                    v-model="$v.form.password.$model"
                    :state="validateState('password')"
                    aria-describedby="input-company-password-feedback"
                    :placeholder="'Password'"
                  ></b-form-input>
                  <span
                    id="input-company-password-feedback"
                    class="form-text text-muted"
                    >Please enter user Password</span
                  >
                </div>
              </div>
              <div class="col-xl-6">
                <div class="form-group">
                  <label>Re-Password</label>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-5 px-6"
                    name="re_password"
                    type="password"
                    v-model="$v.form.re_password.$model"
                    :state="validateState('re_password')"
                    aria-describedby="input-company-re-password-feedback"
                    :placeholder="'Password'"
                  ></b-form-input>
                  <span
                    id="input-company-re-password-feedback"
                    class="form-text text-muted"
                    >Please enter user Password</span
                  >
                </div>
              </div>
            </div>
            <div class="row" v-if="companyFilter == null">
              <div class="col-xl-6">
                <b-form-group
                  id="input-group-3"
                  label="Company:"
                  label-for="input-3"
                >
                  <b-form-select
                    id="input-3"
                    :disabled="form.role == 't1EE5pZNjwkG9nWxZsUd'"
                    v-model="form.company"
                    required
                  >
                    <b-form-select-option
                      :key="company.id + '-user-company'"
                      v-for="company in companies"
                      :value="company.id"
                      >{{ company.name }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="col-xl-6">
                <b-form-group
                  id="input-group-3"
                  label="Roles:"
                  label-for="input-3"
                >
                  <b-form-select
                      :disabled="currentUser.id === $route.params.id"
                      id="input-3" v-model="form.role" required>
                    <b-form-select-option
                      :key="item.id + '-user-company'"
                      v-for="item in roles"
                      :value="item.id"
                      >{{ item.name }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </div>
            </div>

            <div
              v-if="$route.params.id != 0"
              class="pb-5"
              data-wizard-type="step-content"
            >
              <h4 class="font-weight-bold text-dark">Registered Devices</h4>
              <div class="border-bottom mb-5"></div>
              <div class="mb-5 pb-5">
                <div v-for="device in devices" :key="device.id + '-device'" class="font-weight-bold mb-3">
                  {{device.name}}
                </div>
              </div>
            </div>
          </div>

          <button
            v-on:click="submit"
            class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
            data-wizard-type="action-submit"
          >
            {{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}
          </button>
        </form>
      </v-card>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import {mapGetters} from "vuex";

export default {
  name: "UserEdit",
  mixins: [validationMixin],
  watch: {
    "$route.params.id"(val){
      if (val == "0") {
        this.form = {
          first_name: "",
          last_name: "",
          password: "",
          re_password: "",
          company: "",
          email: "",
          role: 1,
        };
        this.devices = [];
      } else {
        this.getData();
        this.getDevices();
      }
    }
  },
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        password: "",
        re_password: "",
        company: "",
        email: "",
        role: 1,
      },
      roles: [],
      companies: [],
      devices: []
    };
  },
  validations: {
    form: {
      first_name: {
        required,
        minLength: minLength(3),
      },
      password: {
        required,
        minLength: minLength(3),
      },
      re_password: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      last_name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "User", route: "../list" },
      { title: "User Edit" },
    ]);
    if (this.$route.params.id != 0) {
      this.getData();
    }
    this.getCompanies();
    this.getDevices();
    this.getRoles();
  },
  methods: {
    async getData() {
      this.form = await this.$store.dispatch(
        "getData",
        "user/" + this.$route.params.id
      );
    },
    async getCompanies() {
      const d = await this.$store.dispatch("getList", {
        target: "companies",
        filters: [],
        page: 1,
        pageLimit: 50,
        sortBy: null,
        sortOrder: null,
        query: { targets: ["name"], value: "" },
      });

      this.companies = d.list;
    },
    async getDevices() {
      const d = await this.$store.dispatch("getList", {
        target: "device",
        filters: [{field: "userId", filter: "==", value: this.$route.params.id}],
        page: 1,
        pageLimit: 50,
        sortBy: null,
        sortOrder: null,
        query: { targets: ["name"], value: "" },
      });

      this.devices = d.list;
    },
    async getRoles() {
      const d = await this.$store.dispatch("getList", {
        target: "roles",
        filters: [],
        page: 1,
        pageLimit: 50,
        sortBy: null,
        sortOrder: null,
        query: { targets: ["name"], value: "" },
      });

      this.roles = d.list;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async submit(e) {
      e.preventDefault();
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      if (this.companyFilter != null) {
        this.form.role = 'fRet2l1sbNh5mQ2iExiu';
        this.form.company = this.companyFilter.id;
      }

      if (this.$route.params.id == 0) {
        this.$store.dispatch("createUserWithoutAuthState", {
          password: this.form.password,
          email: this.form.email
        }).then((v) => {
          this.saveData(v);
        });
      } else {
        await this.saveData(this.$route.params.id);
      }
    },
    async saveData(id) {
      const data = this.form;
      delete data.password;
      delete data.re_password;
      if (id) {
        this.$store.dispatch("saveData", {
          id: id,
          target: "admins",
          form: {}
        }).then((p) => {
          if (p) {
            this.$store.dispatch("saveData", {
              id: id,
              target: "user",
              form: data,
            }).then((e) => {
              if (e) {
                this.$router.push({ name: "user-list" });
              }
            });
          }
        });

      }
    }
  },
  computed: {
    ...mapGetters(["companyFilter", "currentUser"])
  },
};
</script>
